@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('react-toastify/dist/ReactToastify.css');

* {
  font-family: var(--font-inter);
}

@layer components {
  .scroll {
    @apply scrollbar-thin scrollbar-thumb-zinc-200 scrollbar-thumb-rounded-md;
  }
}


@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  label {
    @apply leading-none;
  }
}

@layer utilities {
  .flex-center {
    @apply flex justify-center items-center;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #a0a0ab;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
  font-size: 16px;
}
